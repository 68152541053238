<template>
  <div>
    <div class="row flex" style="height:100vh">
      <!-- Left side: Login form -->
      <div class="col-md-6 p-5">
        <h3 class="text-xl text-center text-oonpayprimary mt-8 mb-8">
          Welcome back!
        </h3>
        <div class="px-4 mb-4">
          <input :class="{ 'is-invalid': submitted && $v.email.$error }" type="text" v-model="email"
            placeholder="Enter Admin Email Address" class="border border-grey rounded w-full p-3" />
          <div v-if="submitted && $v.email.$error" class="invalid-feedback">
            <span v-if="!$v.email.required">Email is required.</span>
            <span v-if="!$v.email.email">Please enter valid email.</span>
          </div>
        </div>
        <div class="px-4 mb-4">
          <input :class="{ 'is-invalid': submitted && $v.password.$error }" type="password" v-model="password"
            placeholder="Password" class="border border-grey rounded w-full p-3" />
          <div v-if="submitted && !$v.password.required" class="invalid-feedback">
            Password is required.
          </div>
        </div>
        <div class="px-4 mb-4 flex">
          <div class="w-1/2 flex items-center">
            <input type="checkbox" class="align-middle cursor-pointer -mt-1" id="remember-me" />
            <label for="remember-me" class="
                  align-middle
                  text-oonpayprimary
                  ml-1
                  pt-2
                  text-md
                  cursor-pointer
                ">Remember me</label>
          </div>
          <div class="w-1/2 text-right">
            <router-link to="/forgotten-password" class="
                  font-semibold
                  text-sm
                  no-underline
                  text-oonpayprimary
                ">Forgot your password?</router-link>
          </div>
        </div>
        <div class="px-4 mb-10">
          <button @keypress.enter.prevent="login" @click="login" class="
                border border-teal-dark
                bg-oonpayprimary
                rounded
                w-full
                px-4
                py-3
                text-white
                font-semibold
              ">
            <b-spinner v-if="loading" small></b-spinner>
            Sign in
          </button>
        </div>
        <div class="bg-grey-lighter text-center text-grey-dark py-5">
          Don't have an account?
          <router-link to="/join-network" class="font-semibold no-underline text-oonpayprimary">Signup</router-link>
        </div>
        <modal @close="toggleModal" :modalActive="modalActive">
          <div class="modal-content">
            <p class="text-center text-danger">{{ errorMessage }}</p>
          </div>
        </modal>
      </div>

      <!-- Right side: Image -->
      <div class="d-none d-lg-flex col-md-6 rightside">

      </div>
    </div>
  </div>

</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import Modal from "../Global/Modal.vue";
export default {
  components: {  Modal },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      submitted: false,
      modalActive: false,
      errorMessage: ""
    };
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },

  methods: {
    async login() {
      // stop here if form is invalid
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const payload = {
        pass_email: this.email,
        pass_password: this.password
      };
      this.error = null;
      try {
        this.loading = true;
        const login = await this.$store.dispatch(
          "crowdhubaccount/Login",
          payload
        );

        //console.log(login)

        const { guid, businessTypeId,permTag } = login.data;
        localStorage.setItem("userinfo", JSON.stringify(login))

        if (login.status == 200) {
          this.loading = false;
          window.localStorage.setItem("businessTypeId", businessTypeId);
          window.localStorage.setItem("merchantGUID", guid);

          window.localStorage.setItem(
            "employeeGUID",
            login.data.employeeAccount.guid
          );
          window.localStorage.setItem("permTag", permTag);

          window.location.href = "/dashboard";
        }

        if (login.status == 204) {
          this.loading = false;
          this.$toasted.error("Invalid username or password", {
            duration: 5000
          });
        }
      } catch (err) {
        this.loading = false;
        this.modalActive = true;
        this.errorMessage = err.response.data;
        // this.$toasted.error(err.response.data,{duration:5000})
      }
    },
     toggleModal(){
      this.modalActive = false
    },
  }
};
</script>

<style lang="scss" scoped>
.rightside {
  background-image: url('../../assets/auth/bg20.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 
}
</style>
